<template>
  <!-- begin::Companies edit for Superadmin -->
  <div>
    <div class="manager-title">
      <div>
        {{
          CompanyTypeID == companyTypesList.ID_SENDER
            ? $t("COMPANIES.SENDERS.TITLE_EDIT")
            : $t("COMPANIES.TRANSPORTERS.TITLE_EDIT")
        }}
        -
        {{ FormBasic.Fields.Code }}
        <hr class="manager-title-sub" />
      </div>
    </div>

    <div class="white-box shadow-box">
      <!-- begin::AdminUser data -->
      <div class="manager-subtitle">
        1. {{ $t("COMPANIES.SUBTITLE_FORM_ADMIN_USER") }}
      </div>

      <v-form
        ref="formAdminUser"
        v-model="FormAdminUser.Valid"
        lazy-validation
        class="form"
      >
        <div class="row">
          <div class="form-group col-lg-4">
            <label>
              {{ $t("COMPANIES.COMPANY_CLASS") }}
            </label>
            <select
              class="form-control select-down"
              v-model="FormBasic.Fields.CompanyClassID"
              required
            >
              <option
                v-for="cc in filterCompanyClassOptions()"
                :key="cc.CompanyClassID"
                :value="cc.CompanyClassID"
                class="text-primary"
              >
                {{ cc.Name }}
              </option>
            </select>
          </div>
        </div>

        <div class="row">
          <div class="form-group col-lg-4">
            <label>
              {{ $t("MY_PROFILE.FULLNAME") }}
            </label>
            <v-text-field
              class="form-control"
              v-model="FormAdminUser.Fields.Name"
              :placeholder="$t('MY_PROFILE.FULLNAME')"
              required
              :rules="[rules.required]"
            />
          </div>

          <div class="form-group col-lg-4">
            <label>
              {{ $t("MY_PROFILE.EMAIL") }}
            </label>
            <v-text-field
              class="form-control"
              v-model="FormAdminUser.Fields.Email"
              :placeholder="$t('MY_PROFILE.EMAIL')"
              readonly
            >
              <template v-slot:prepend-inner>
                <inline-svg src="/media/icons/contact-gray.svg" />
              </template>
            </v-text-field>
          </div>

          <div class="form-group col-lg-4">
            <label>
              {{ $t("MY_PROFILE.PHONE") }}
            </label>
            <v-text-field
              class="form-control"
              v-model="FormAdminUser.Fields.Phone"
              :placeholder="$t('MY_PROFILE.PHONE')"
              required
              :rules="[rules.required]"
            >
              <template v-slot:prepend-inner>
                <inline-svg src="/media/icons/phone-gray.svg" />
              </template>
            </v-text-field>
          </div>
        </div>

        <div class="row">
          <div class="form-group col-lg-4">
            <label>
              {{ $t("MY_PROFILE.PASSWORD_NEW") }}
            </label>
            <v-text-field
              type="password"
              class="form-control"
              v-model="FormAdminUser.Fields.PasswordNew"
              :placeholder="$t('MY_PROFILE.PASSWORD_ONLY_TO_CHANGE')"
              :rules="[rules.emptyOrMin6]"
              autocomplete="off"
            >
              <template v-slot:prepend-inner>
                <inline-svg src="/media/icons/lock-alt-1-lock-gray.svg" />
              </template>
            </v-text-field>
          </div>

          <div class="form-group col-lg-4">
            <label>
              {{ $t("MY_PROFILE.PASSWORD_NEW_CONFIRM") }}
            </label>
            <v-text-field
              type="password"
              class="form-control"
              v-model="FormAdminUser.Fields.PasswordConfirm"
              :placeholder="$t('MY_PROFILE.PASSWORD_NEW_CONFIRM')"
              :rules="[rules.confirm]"
              autocomplete="off"
            >
              <template v-slot:prepend-inner>
                <inline-svg src="/media/icons/lock-alt-1-lock-gray.svg" />
              </template>
            </v-text-field>
          </div>
        </div>
      </v-form>
      <!-- end::AdminUser data -->

      <!-- begin::Basic Company data -->
      <div class="manager-subtitle">
        2. {{ $t("COMPANIES.SUBTITLE_FORM_BASIC") }}
      </div>

      <v-form
        ref="formBasic"
        v-model="FormBasic.Valid"
        lazy-validation
        class="form"
      >
        <div class="row">
          <div class="form-group col-lg-4">
            <label>
              {{ $t("COMPANIES.TRADE_NAME") }}
            </label>
            <v-text-field
              class="form-control"
              v-model="FormBasic.Fields.TradeName"
              :placeholder="$t('COMPANIES.TRADE_NAME')"
              required
              :rules="[rules.required]"
            />
          </div>

          <div class="form-group col-lg-4">
            <label>
              {{ $t("COMPANIES.FISCAL_NAME") }}
            </label>
            <v-text-field
              class="form-control"
              v-model="FormBasic.Fields.FiscalName"
              :placeholder="$t('COMPANIES.FISCAL_NAME_INFO')"
              required
              :rules="[rules.required]"
            />
          </div>

          <div class="form-group col-lg-4">
            <label>
              {{ $t("COMPANIES.FISCAL_ID") }}
            </label>
            <v-text-field
              class="form-control"
              v-model="FormBasic.Fields.FiscalID"
              :placeholder="$t('COMPANIES.FISCAL_ID')"
              required
              :rules="[rules.required]"
            />
          </div>

          <div class="form-group col-lg-4">
            <label>
              {{ $t("COMPANIES.BRAND_NAME") }}
              <span class="font-weight-normal">
                ({{ $t("GENERIC.OPTIONAL") }})
              </span>
            </label>
            <v-text-field
              class="form-control"
              v-model="FormBasic.Fields.BrandName"
              :placeholder="$t('COMPANIES.BRAND_NAME')"
            />
          </div>
        </div>

        <div class="row">
          <div class="form-group col-lg-4">
            <label>
              {{ $t("COMPANIES.CONTACT_NAME") }}
            </label>
            <v-text-field
              class="form-control"
              v-model="FormBasic.Fields.ContactName"
              :placeholder="$t('COMPANIES.CONTACT_NAME')"
              required
              :rules="[rules.required]"
            />
          </div>

          <div class="form-group col-lg-4">
            <label>
              {{ $t("COMPANIES.CONTACT_EMAIL") }}
            </label>
            <v-text-field
              class="form-control"
              v-model="FormBasic.Fields.ContactEmail"
              :placeholder="$t('COMPANIES.CONTACT_EMAIL')"
              required
              :rules="[rules.required, rules.email]"
            >
              <template v-slot:prepend-inner>
                <inline-svg src="/media/icons/contact-gray.svg" />
              </template>
            </v-text-field>
          </div>

          <div class="form-group col-lg-4">
            <label>
              {{ $t("COMPANIES.CONTACT_PHONE") }}
            </label>
            <v-text-field
              class="form-control"
              v-model="FormBasic.Fields.ContactPhone"
              :placeholder="$t('COMPANIES.CONTACT_PHONE')"
              required
              :rules="[rules.required]"
            >
              <template v-slot:prepend-inner>
                <inline-svg src="/media/icons/phone-gray.svg" />
              </template>
            </v-text-field>
          </div>

          <div class="form-group col-lg-4">
            <label>
              {{ $t("COMPANIES.FULL_ADDRESS") }}
            </label>
            <VueGoogleAutocomplete
              ref="autocompleteAddress"
              id="autocompleteAddress"
              country="es"
              :fields="['address_components', 'geometry', 'id', 'name']"
              v-model="FormBasic.Fields.Street"
              :rules="[rules.required]"
              required
              classname="form-control"
              placeholder=""
              @placechanged="onAutocompletePlaceChanged"
              @inputChange="onAutocompleteInputChange"
            />
            <div
              v-if="!addressValid"
              class="v-text-field__details pt-2 pl-5 pb-0 m-0"
            >
              <div class="v-messages theme--light error--text" role="alert">
                <div class="v-messages__wrapper">
                  <div class="v-messages__message">
                    {{ $t("VALIDATION.REQUIRED_FIELD") }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group col-lg-2">
            <label>
              {{ $t("COMPANIES.POSTAL_CODE") }}
            </label>
            <v-text-field
              class="form-control"
              v-model="FormBasic.Fields.PostalCode"
              :placeholder="$t('COMPANIES.POSTAL_CODE')"
              required
              :rules="[rules.required]"
              @keyup="onPostalCodeChanged"
            />
          </div>

          <div class="form-group col-lg-3">
            <label>
              {{ $t("COMPANIES.CITY") }}
            </label>
            <v-text-field
              class="form-control"
              v-model="FormBasic.Fields.City"
              :placeholder="$t('COMPANIES.CITY')"
              required
              :rules="[rules.required]"
            />
          </div>

          <div class="form-group col-lg-3">
            <label>
              {{ $t("COMPANIES.PROVINCE") }}
            </label>
            <select
              class="form-control select-down"
              v-model="FormBasic.Fields.ProvinceID"
              required
            >
              <option
                v-for="p in FormBasic.ProvinceOptions"
                :key="p.ProvinceID"
                :value="p.ProvinceID"
                class="text-primary"
              >
                {{ p.Name }}
              </option>
            </select>
          </div>
        </div>
      </v-form>
      <!-- end::Basic Company data -->

      <!-- begin::Payment data for Transporters -->
      <div v-if="CompanyTypeID == companyTypesList.ID_TRANSPORTER">
        <div class="manager-subtitle">
          3. {{ $t("COMPANIES.PAYMENT_DATA.TITLE") }}
        </div>
        <p>
          {{ $t("COMPANIES.PAYMENT_DATA.TRANSPORTER.INTERNAL_1") }}
        </p>

        <div class="row">
          <div class="form-group col-lg-4 mb-0">
            <label>
              {{
                $t("COMPANIES.PAYMENT_DATA.TRANSPORTER.PAYMENT_PERCENT_SUPER")
              }}
            </label>
            <v-text-field
              class="form-control shortInput"
              v-model="FormBasic.Fields.PaymentPercent"
              type="number"
              min="0"
              max="100.00"
              step="0.01"
              required
              :rules="[rules.requiredMin0, rules.max100]"
            />
          </div>

          <div class="form-group col-lg-4 mb-0">
            <label>
              {{
                $t("COMPANIES.PAYMENT_DATA.TRANSPORTER.MULTISAFEPAY_MERCHANT")
              }}
            </label>
            <v-text-field
              class="form-control"
              v-model="FormBasic.Fields.MultisafepayMerchantID"
              :placeholder="
                $t('COMPANIES.PAYMENT_DATA.TRANSPORTER.MULTISAFEPAY_MERCHANT')
              "
            />
          </div>
        </div>

        <p class="mt-6">
          {{ $t("COMPANIES.PAYMENT_DATA.TRANSPORTER.EXTERNAL_1") }}
          <br />
          {{ $t("COMPANIES.PAYMENT_DATA.TRANSPORTER.EXTERNAL_2") }}
        </p>

        <div class="row">
          <div class="form-group col-lg-4 mb-0">
            <label>
              {{ $t("COMPANIES.PAYMENT_DATA.BANK_NAME") }}
            </label>
            <v-text-field
              class="form-control"
              v-model="FormBasic.Fields.BankName"
            />
          </div>

          <div class="form-group col-lg-4 mb-0">
            <label>
              {{ $t("COMPANIES.PAYMENT_DATA.IBAN") }}
            </label>
            <v-text-field
              class="form-control"
              v-model="FormBasic.Fields.Iban"
            />
          </div>

          <div class="form-group col-lg-4 mb-0">
            <label>
              {{ $t("COMPANIES.PAYMENT_DATA.SWIFT") }}
            </label>
            <v-text-field
              class="form-control"
              v-model="FormBasic.Fields.Swift"
            />
          </div>
        </div>
      </div>
      <!-- end::Payment data for Transporters -->

      <!-- begin::Payment data for SendersPremium -->
      <div
        v-if="
          FormSubscription.Fields.SubscriptionTypeID ===
            subscriptionTypesList.ID_SENDER_PREMIUM
        "
      >
        <div class="manager-subtitle">
          3. {{ $t("COMPANIES.PAYMENT_DATA.TITLE") }}
        </div>
        <p>
          {{ $t("COMPANIES.PAYMENT_DATA.SENDER_PREMIUM.SUBTITLE_SUPER") }}
        </p>

        <div class="row">
          <div class="form-group col-lg-4 mb-0">
            <label>
              {{
                $t(
                  "COMPANIES.PAYMENT_DATA.SENDER_PREMIUM.PAYMENT_PERCENT_SUPER"
                )
              }}
            </label>
            <v-text-field
              class="form-control shortInput"
              v-model="FormBasic.Fields.PaymentPercent"
              type="number"
              min="0"
              max="100.00"
              step="0.01"
              required
              :rules="[rules.requiredMin0, rules.max100]"
            />
          </div>

          <div class="form-group col-lg-8 mb-0">
            <label>
              {{ $t("COMPANIES.PAYMENT_DATA.SENDER_PREMIUM.STATUS") }}
            </label>
            <select
              class="form-control select-down"
              v-model="FormBasic.Fields.Active"
            >
              <option
                v-for="i in [0, 1]"
                :key="'SenderPremiumActive' + i"
                :value="i"
                class="text-primary"
              >
                {{
                  $t("COMPANIES.PAYMENT_DATA.SENDER_PREMIUM.STATUS_" + i) +
                    " - " +
                    $t(
                      "COMPANIES.PAYMENT_DATA.SENDER_PREMIUM.STATUS_" +
                        i +
                        "_INFO"
                    )
                }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <!-- end::Payment data for SendersPremium -->

      <!-- begin::Subscription data -->
      <div class="manager-subtitle mt-9">
        {{
          FormSubscription.Fields.SubscriptionTypeID ===
          subscriptionTypesList.ID_SENDER_BASIC
            ? "3"
            : "4"
        }}.
        {{ $t("COMPANIES.SUBTITLE_FORM_SUBSCRIPTION") }}
      </div>

      <v-form
        ref="formAdminUser"
        v-model="FormAdminUser.Valid"
        lazy-validation
        class="form"
      >
        <div class="row">
          <div class="form-group col-lg-4">
            <label>
              {{ $t("COMPANIES.SUBSCRIPTION_TYPE") }}
            </label>
            <select
              class="form-control select-down"
              v-model="FormSubscription.Fields.SubscriptionTypeID"
              required
            >
              <option
                v-for="st in FormSubscription.SubscriptionTypeOptions"
                :key="st.SubscriptionTypeID"
                :value="st.SubscriptionTypeID"
                class="text-primary"
              >
                {{ st.Name }}
              </option>
            </select>
          </div>

          <div class="form-group col-lg-4">
            <label>
              {{ $t("SUBSCRIPTION_TYPES.PRICE_PER_MONTH") }}
            </label>
            <v-text-field
              class="form-control"
              v-model="FormSubscription.Fields.PricePerMonth"
              :placeholder="$t('SUBSCRIPTION_TYPES.PRICE_PER_MONTH')"
              required
              :rules="[rules.requiredMin0]"
            />
          </div>

          <div class="form-group col-lg-4">
            <label>
              {{ $t("SUBSCRIPTION_TYPES.MONTHS") }}
            </label>
            <v-text-field
              class="form-control"
              v-model="FormSubscription.Fields.Months"
              :placeholder="$t('SUBSCRIPTION_TYPES.MONTHS')"
              required
              :rules="[rules.requiredMin1]"
            />
          </div>

          <div class="form-group col-lg-4">
            <p>
              <strong>{{ $t("COMPANIES.REGISTER_DATE") }}:</strong>
              {{ FormSubscription.CreatedAt }}
            </p>
            <p>
              <strong>{{ $t("COMPANIES.RENEWAL_DATE") }}:</strong>
              {{ FormSubscription.ActiveUntil }}
            </p>
            <label class="mb-5">
              {{ $t("COMPANIES.SUBSCRIPTION_STATUS") }}
            </label>
            <br />
            <span
              class="white-box border-2 py-3 px-12 text-uppercase font-weight-bolder"
              :class="[formatCurrentSubscriptionStatusClass()]"
              style="white-space: nowrap"
            >
              {{
                getSubscriptionStatusName(
                  FormSubscription.CurrentSubscriptionStatusID
                )
              }}
            </span>
            <br />
            <button
              class="btn btn-pill btn-white button-filter miniButton mt-6 mb-0"
              style="min-width: 0"
              @click="
                FormSubscription.SubscriptionHistoryOpen = !FormSubscription.SubscriptionHistoryOpen
              "
            >
              <inline-svg
                :src="
                  '/media/icons/chevron-' +
                    (FormSubscription.SubscriptionHistoryOpen ? 'up' : 'down') +
                    '-primary.svg'
                "
                class="mr-3"
              />
              {{ $t("GENERIC.DETAILS") }}
            </button>
          </div>

          <div class="form-group col-lg-8">
            <label>
              {{ $t("COMPANIES.CHANGE_SUBSCRIPTION_STATUS") }}
            </label>
            <select
              class="form-control select-down"
              v-model="FormSubscription.Fields.NewSubscriptionStatusID"
            >
              <option
                v-for="ss in FormSubscription.ChangeSubscriptionStatusOptions"
                :key="ss.SubscriptionStatusID"
                :value="ss.SubscriptionStatusID"
                class="text-primary"
              >
                {{ ss.Name }}
              </option>
            </select>
          </div>

          <!-- begin::SubscriptionHistoryDetails -->
          <div
            v-if="FormSubscription.SubscriptionHistoryOpen"
            class="col-lg-12 mb-6"
          >
            <v-simple-table id="SubscriptionHistoryTable">
              <tbody>
                <tr
                  v-for="(sh, i) in FormSubscription.SubscriptionHistory"
                  :key="i"
                >
                  <td style="width: 25%">
                    {{ formatDatetime(sh.CreatedAt) }}
                  </td>
                  <td>
                    {{
                      $t(
                        "SUBSCRIPTION_HISTORY_ACTION_NAMES_BY_ID." +
                          sh.SubscriptionHistoryActionID
                      )
                    }}
                  </td>
                  <td>
                    {{ sh.Details }}
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </div>
          <!-- end::SubscriptionHistoryDetails -->

          <div class="form-group col-lg-12">
            <label>
              {{ $t("COMPANIES.NOTES") }}
              <span class="font-weight-normal">
                ({{ $t("GENERIC.OPTIONAL") }})
              </span>
            </label>
            <textarea
              rows="3"
              class="form-control"
              v-model="FormSubscription.Fields.Notes"
            ></textarea>
          </div>
        </div>
      </v-form>
      <!-- end::Subscription data -->

      <!-- begin::Action buttons -->
      <div class="row button-row">
        <button
          class="btn btn-pill btn-white button-form button-form-primary mr-9"
          @click="
            $router.push(
              CompanyTypeID == companyTypesList.ID_SENDER
                ? '/manager/senders'
                : '/manager/transporters'
            )
          "
        >
          <inline-svg
            src="/media/icons/arrow-return-primary.svg"
            class="mr-3"
          />
          {{ $t("GENERIC.BACK") }}
        </button>

        <button
          ref="multiFormSubmit"
          class="btn btn-pill btn-tertiary button-form"
          :disabled="!FormAdminUser.Valid || !FormBasic.Valid || !addressValid"
          @click="onSubmitMultiForm"
        >
          {{ $t("GENERIC.SAVE") }}
        </button>
      </div>
      <!-- end::Action buttons -->
    </div>
  </div>
  <!-- end::Companies edit for Superadmin -->
</template>

<style scoped>
#SubscriptionHistoryTable td {
  font-size: 1em;
}
</style>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import i18nService from "@/core/services/i18n.service.js";

import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME
} from "@/core/services/store/htmlclass.module.js";

import VueGoogleAutocomplete from "vue-google-autocomplete";

import CompanyService from "@/core/services/api/v2/company.service";
import CompanyClassService from "@/core/services/api/v2/companyclass.service";
import ProvinceService from "@/core/services/api/v2/province.service";
import SubscriptionService from "@/core/services/api/v2/subscription.service";
import SubscriptionStatusService from "@/core/services/api/v2/subscriptionstatus.service";
import SubscriptionTypeService from "@/core/services/api/v2/subscriptiontype.service";
import UserService from "@/core/services/api/v2/user.service";

export default {
  name: "CompaniesSuperadminEdit",
  components: {
    VueGoogleAutocomplete
  },

  computed: {
    ...mapGetters(["companyTypesList", "subscriptionTypesList"]),
    addressValid() {
      return (
        this.FormBasic.Fields.Street && this.FormBasic.Fields.Street.length > 0
      );
    }
  },

  data() {
    return {
      CompanyID: 0,
      CompanyTypeID: 0,
      FormAdminUser: {
        Valid: true,
        Fields: {
          Email: "",
          Name: "",
          Phone: "",
          PasswordNew: "",
          PasswordConfirm: ""
        }
      },
      FormBasic: {
        Valid: true,
        Fields: {
          Code: "",
          TradeName: "",
          FiscalName: "",
          FiscalID: "",
          BrandName: "",
          ContactName: "",
          ContactPhone: "",
          ContactEmail: "",
          Street: "",
          PostalCode: "",
          City: "",
          ProvinceID: 0,
          CompanyClassID: 0,
          MultisafepayMerchantID: "",
          PaymentPercent: 0,
          BankName: "",
          Iban: "",
          Swift: "",
          Active: 0
        },
        CompanyClassOptions: [],
        ProvinceOptions: []
      },
      FormSubscription: {
        Valid: true,
        Fields: {
          SubscriptionTypeID: 0,
          NewSubscriptionStatusID: 0,
          PricePerMonth: 0,
          Months: 1,
          Notes: ""
        },
        CreatedAt: "",
        ActiveUntil: "",
        CurrentSubscriptionStatusID: 0,
        SubscriptionTypeOptions: [],
        SubscriptionStatusOptions: [],
        ChangeSubscriptionStatusOptions: [],
        SubscriptionHistory: [],
        SubscriptionHistoryOpen: false
      },
      DateFormatEN: "YYYY-MM-DD",
      DateFormatES: "DD/MM/YYYY",
      DateFormatLang: "DD/MM/YYYY",
      rules: {
        required: value => !!value || this.$i18n.t("VALIDATION.REQUIRED_FIELD"),
        email: value =>
          !!/.+@.+\..+/.test(value) || this.$i18n.t("VALIDATION.INVALID_EMAIL"),
        emptyOrMin6: value => {
          if (!value || value.length === 0 || value.length >= 6) {
            return true;
          } else {
            return this.$i18n.t("VALIDATION.MIN_LENGTH_FIELD") + " 6";
          }
        },
        confirm: value =>
          value === this.FormAdminUser.Fields.PasswordNew ||
          this.$i18n.t("MY_PROFILE.PASSWORD_MISMATCH"),
        requiredMin0: value => {
          let value2 = parseInt(value);
          if (isNaN(value2) || value2 < 0) {
            return this.$i18n.t("VALIDATION.MIN_VALUE", { min: 0 });
          } else {
            return true;
          }
        },
        requiredMin1: value => {
          let value2 = parseInt(value);
          if (isNaN(value2) || value2 < 1) {
            return this.$i18n.t("VALIDATION.MIN_VALUE", { min: 1 });
          } else {
            return true;
          }
        },
        max100: value =>
          parseInt(value) <= 100 ||
          this.$i18n.t("VALIDATION.MAX_VALUE", { max: 100 })
      }
    };
  },

  created() {
    // Pick format for dates
    let userLang = i18nService.getActiveLanguage();
    this.DateFormatLang =
      userLang === "en" ? this.DateFormatEN : this.DateFormatES;

    this.loadCompanyClassOptions();
  },

  mounted() {
    this.CompanyID = parseInt(this.$route.params.id);
    this.loadCompanyData();
  },

  methods: {
    loadCompanyClassOptions() {
      CompanyClassService.listAll().then(response => {
        this.FormBasic.CompanyClassOptions = response;
        this.loadProvinceOptions();
      });
    },

    filterCompanyClassOptions() {
      // As of 2022-01 avoid Class=Particular for Transporters
      return this.FormBasic.CompanyClassOptions.filter(cco => {
        if (this.CompanyTypeID != this.companyTypesList.ID_TRANSPORTER) {
          return true;
        } else {
          return cco.CompanyClassID > 1;
        }
      });
    },

    loadProvinceOptions() {
      ProvinceService.listAll().then(response => {
        this.FormBasic.ProvinceOptions = response;
        this.loadSubscriptionStatusOptions();
      });
    },

    loadSubscriptionStatusOptions() {
      SubscriptionStatusService.listAll().then(response => {
        this.FormSubscription.SubscriptionStatusOptions = response;
      });
    },
    getSubscriptionStatusName(ssID) {
      let name = " - ";

      this.FormSubscription.SubscriptionStatusOptions.forEach(ss => {
        if (ss.SubscriptionStatusID === ssID) {
          name = ss.Name;
        }
      });

      return name;
    },

    loadCompanyData() {
      // Show page loader
      this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");

      // 1. Basic data
      CompanyService.getSomeCompanyProfile(this.CompanyID).then(response => {
        if (response === 404) {
          this.$router.push("/manager/");
        } else {
          this.CompanyTypeID = response.CompanyTypeID;

          for (var f1 in this.FormBasic.Fields) {
            this.FormBasic.Fields[f1] = response[f1];
          }

          // 2. Its Admin user
          UserService.getAdminProfileByCompany(this.CompanyID).then(
            response2 => {
              if (response2 !== 404) {
                this.FormAdminUser.Fields.Email = response2.Email;
                this.FormAdminUser.Fields.Name = response2.Name;
                this.FormAdminUser.Fields.Phone = response2.Phone;
                this.FormAdminUser.Fields.PasswordNew = "";
                this.FormAdminUser.Fields.PasswordConfirm = "";
              }

              // 3. Its subscription and available types
              this.FormSubscription.SubscriptionTypeOptions = [];
              SubscriptionTypeService.listAll(this.CompanyTypeID).then(
                typesList => {
                  let namePrefix =
                    this.$t("COMPANY_TYPE_NAMES_BY_ID." + this.CompanyTypeID) +
                    " - ";

                  typesList.forEach(st => {
                    this.FormSubscription.SubscriptionTypeOptions.push({
                      SubscriptionTypeID: st.SubscriptionTypeID,
                      Name: namePrefix + st.Name
                    });
                  });
                }
              );

              SubscriptionService.getSubscriptionProfileByCompany(
                this.CompanyID
              ).then(response3 => {
                if (response3 !== 404) {
                  for (var f3 in this.FormSubscription.Fields) {
                    this.FormSubscription.Fields[f3] = response3[f3];
                  }
                  this.FormSubscription.Fields.SubscriptionTypeID =
                    response3.SubscriptionTypeID;
                  this.FormSubscription.Fields.PricePerMonth =
                    response3.PricePerMonth;
                  this.FormSubscription.Fields.Months = response3.Months;
                  this.FormSubscription.Fields.Notes = response3.Notes;

                  this.FormSubscription.CreatedAt = this.formatDatetime(
                    response3.CreatedAt
                  );
                  this.FormSubscription.ActiveUntil = this.formatDatetime(
                    response3.ActiveUntil
                  );
                  this.FormSubscription.SubscriptionHistory =
                    response3["SubscriptionHistory"];

                  // Depending on its current status, pick options for the "Change Status" selector
                  this.FormSubscription.Fields.NewSubscriptionStatusID = 0;
                  this.FormSubscription.CurrentSubscriptionStatusID =
                    response3.SubscriptionStatusID;

                  this.FormSubscription.ChangeSubscriptionStatusOptions = [];
                  // 1. Leave as is
                  this.FormSubscription.ChangeSubscriptionStatusOptions.push({
                    SubscriptionStatusID: 0,
                    Name: " - - "
                  });

                  // 2. Manually Paid X months more
                  this.FormSubscription.ChangeSubscriptionStatusOptions.push({
                    SubscriptionStatusID: 3,
                    Name: this.$t("COMPANIES.CHANGE_TO_PAID")
                  });

                  if (
                    this.FormSubscription.CurrentSubscriptionStatusID === 1 ||
                    this.FormSubscription.CurrentSubscriptionStatusID === 2
                  ) {
                    // 3. From Unpaid to Cancelled
                    this.FormSubscription.ChangeSubscriptionStatusOptions.push({
                      SubscriptionStatusID: 4,
                      Name: this.$t("COMPANIES.CHANGE_TO_CANCELLED")
                    });
                  } else if (
                    this.FormSubscription.CurrentSubscriptionStatusID === 3
                  ) {
                    // 4. From Paid to Unpaid
                    this.FormSubscription.ChangeSubscriptionStatusOptions.push({
                      SubscriptionStatusID: 2,
                      Name: this.$t("COMPANIES.CHANGE_TO_UNPAID")
                    });
                  }
                }

                // Remove page loader
                this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
              });
            }
          );
        }
      });
    },

    formatDatetime(date) {
      if (date && date.length > 0) {
        return moment(date).format(this.DateFormatLang + " HH:mm");
      } else {
        return "-";
      }
    },

    formatCurrentSubscriptionStatusClass() {
      if (this.FormSubscription.CurrentSubscriptionStatusID == 4) {
        // Cancelled
        return "text-danger border-danger";
      } else if (this.FormSubscription.CurrentSubscriptionStatusID === 3) {
        // Paid
        return "text-success border-success";
      } else {
        return "";
      }
    },

    onAutocompletePlaceChanged(addressData) {
      this.FormBasic.Fields.Street =
        addressData.route +
        (addressData.street_number ? " " + addressData.street_number : "");

      this.FormBasic.Fields.PostalCode = addressData.postal_code;
      this.FormBasic.Fields.City = addressData.locality;
      this.onPostalCodeChanged();

      // Clean extra values from Street box
      this.$refs.autocompleteAddress.$refs.autocomplete.value = this.FormBasic.Fields.Street;
    },

    onAutocompleteInputChange(e) {
      if (e.newVal.trim().length === 0) {
        this.FormBasic.Fields.Street = "";
      }
    },

    onPostalCodeChanged() {
      // Convert PostalCode to an item in our ProvinceID selector
      // N.B: Province is CRITICAL because it sets the fare,
      // but Google doesn't give it,
      // that's why we have to guess it via PostalCode...
      // and why we restrict VueGoogleAutocomplete component to country="es"
      let newPostalCode = parseInt(this.FormBasic.Fields.PostalCode);
      let newProvinceID = 1;

      for (let p = 0; p < this.FormBasic.ProvinceOptions.length; p++) {
        let maybeProvince = this.FormBasic.ProvinceOptions[p];
        let pcStart = parseInt(maybeProvince.PostalCodeStart);
        let pcEnd = parseInt(maybeProvince.PostalCodeEnd);
        if (newPostalCode >= pcStart && newPostalCode <= pcEnd) {
          newProvinceID = maybeProvince.ProvinceID;
          break;
        }
      }

      this.FormBasic.Fields.ProvinceID = newProvinceID;
    },

    onSubmitMultiForm(e) {
      e.preventDefault();

      if (
        this.$refs.formBasic.validate() &&
        this.$refs.formAdminUser.validate()
      ) {
        // Disable submit button
        this.FormAdminUser.Valid = false;
        this.FormBasic.Valid = false;
        this.FormSubscription.Valid = false;

        // Send update request:
        // 1. Admin user data
        UserService.updateAdminProfileByCompany(
          this.CompanyID,
          this.FormAdminUser.Fields
        ).then(statusCode => {
          if (statusCode >= 400) {
            let whyError = this.$i18n.t("GENERIC.TRY_LATER");
            if (statusCode === 412) {
              whyError = this.$i18n.t("MY_PROFILE.ERROR_412");
            } else if (statusCode === 400) {
              whyError = this.$i18n.t("VALIDATION.MISSING_REQUIRED_FIELDS");
            }

            this.$bvToast.toast(whyError, {
              title: this.$i18n.t("COMPANIES.SAVE_ERROR_FORM_USER"),
              variant: "danger",
              solid: true
            });
          } else {
            this.$bvToast.toast(" ", {
              title: this.$i18n.t("COMPANIES.SAVE_OK_FORM_USER"),
              variant: "success",
              solid: true
            });
          }

          // Reset submit button
          this.FormAdminUser.Valid = true;
        });

        // 2. Basic company data
        CompanyService.updateSomeCompanyProfile(
          this.CompanyID,
          this.FormBasic.Fields
        ).then(statusCode => {
          if (statusCode >= 400) {
            let whyError = this.$i18n.t("GENERIC.TRY_LATER");
            if (statusCode === 400) {
              whyError = this.$i18n.t("VALIDATION.MISSING_REQUIRED_FIELDS");
            }

            this.$bvToast.toast(whyError, {
              title: this.$i18n.t("COMPANIES.SAVE_ERROR_FORM_BASIC"),
              variant: "danger",
              solid: true
            });
          } else {
            this.$bvToast.toast(" ", {
              title: this.$i18n.t("COMPANIES.SAVE_OK_FORM_BASIC"),
              variant: "success",
              solid: true
            });
          }

          // Reset submit button
          this.FormBasic.Valid = true;
        });

        // 3. Subscription data
        SubscriptionService.updateSubscriptionProfileByCompany(
          this.CompanyID,
          this.FormSubscription.Fields
        ).then(statusCode => {
          if (statusCode >= 400) {
            let whyError = this.$i18n.t("GENERIC.TRY_LATER");
            if (statusCode === 400) {
              whyError = this.$i18n.t("VALIDATION.MISSING_REQUIRED_FIELDS");
            }

            this.$bvToast.toast(whyError, {
              title: this.$i18n.t("COMPANIES.SAVE_ERROR_FORM_SUBSCRIPTION"),
              variant: "danger",
              solid: true
            });
          } else {
            this.$bvToast.toast(" ", {
              title: this.$i18n.t("COMPANIES.SAVE_OK_FORM_SUBSCRIPTION"),
              variant: "success",
              solid: true
            });

            // If Subscription status changed, reload to get new status & date
            if (this.FormSubscription.Fields.NewSubscriptionStatusID > 0) {
              setTimeout(() => this.loadCompanyData(), 2000);
            }
          }

          // Reset submit button
          this.FormSubscription.Valid = true;
        });
      }
    }
  }
};
</script>
